import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const contactHeight = 30;

export function Contact() {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#e1e1e1",
        flexGrow: 1,
        alignContent: "center",
        minHeight: contactHeight,
      }}
    >
      {/* <Grid
        item
        xs={6}
        sx={{
          paddingLeft: 5,
          alignContent: "center",
          textAlign: "start",
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
          <Call fontSize="inherit" /> +49 176 99 22 00 22
        </Typography>
      </Grid>
 */}
      <Grid
        xs={12}
        item
        sx={{
          paddingRight: 5,
          alignContent: "center",
          textAlign: "end",
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
          <EmailIcon fontSize="inherit" /> dominik@elsbroek.com
        </Typography>
      </Grid>
    </Grid>
  );
}
